/* ===========================
22. Team Page
 =============================*/

.team-page {
	.team-section {
		padding-bottom: 60px;

		&:before {
			display: none;
		}

		border: 0;
		margin: 0;

		.team-wrap {
			margin: 0;

			.team-item {
				margin-bottom: 30px;

				@media(max-width:1199px) {
					max-width: 300px;
					margin: 0 auto;
					margin-bottom: 30px;
				}

				&:hover {
					&::after {
						height: 78%;
						bottom: 0;
					}

					.team-text {
						bottom: 0px;
					}
				}

				.right-title {
					@media(max-width:1700px) {
						right: -28%;
					}

					@media(max-width:1399px) {
						right: -38%;
					}

					@media(max-width:767px) {
						right: -48%;
					}
				}
			}
		}
	}
}


// team-single-page

.team-single-page {
	.team-single-sidebar {
		@media(max-width:991px) {
			margin-top: 50px;
		}

		.team-item {
			position: relative;
			overflow: hidden;
			margin-bottom: 30px;
			max-width: 100%;

			&:before {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 78%;
				content: "";
				background: $dark-gray;
				border: 1px solid rgba(255, 255, 255, 0.10);
			}

			&::after {
				position: absolute;
				left: 0;
				bottom: -20px;
				width: 100%;
				height: 0;
				content: "";
				background: $theme-primary-color;
				opacity: .75;
				border: 1px solid rgba(255, 255, 255, 0.10);
				transition: all .3s;
			}

			.team-img-wrap {
				position: relative;
				z-index: 1;

				.team-shape {
					position: absolute;
					left: 10px;
					top: 5px;
					width: 100%;
					height: 100%;
					z-index: -1;
				}
			}

			.team-img {
				margin: 0 auto;
				position: relative;

				img {
					transition: all .3s;
					display: unset;
					max-width: 100%;					
				}

			}

			.right-title {
				position: absolute;
				right: -23%;
				bottom: 40%;
				transform: rotate(-90deg);
				z-index: 1;

				@media(max-width:1700px) {
					width: 320px;
					right: -28%;
				}

				@media(max-width:450px) {
					right: -35%;
				}

				h3 {
					font-family: $heading-font;
					font-size: 30px;
					font-style: normal;
					font-weight: 700;
					line-height: 40px;
					letter-spacing: 5px;
					display: inline-block;
					background-clip: text;
					-webkit-text-fill-color: transparent;
					-webkit-text-stroke: 0.5px rgba(255, 255, 255, 0.6);
				}
			}
		}

		.team-info {
			ul {
				li {
					font-size: 30px;
					font-style: normal;
					font-weight: 700;
					line-height: 40px;
					font-family: $heading-font;
					color: $dark-gray;

					@media(max-width:1199px) {
						font-size: 22px;
					}

					&+li {
						margin-top: 20px;
					}

					i {
						margin-right: 10px;
						color: $theme-primary-color;
					}
				}
			}
		}

		.team-social {
			margin-top: 30px;

			ul {
				display: flex;

				li {
					&+li {
						margin-left: 20px;
					}

					a {
						display: block;
						width: 50px;
						height: 50px;
						line-height: 56px;
						background: $text-color;
						color: $white;
						border-radius: 50%;
						text-align: center;
					}
				}
			}
		}
	}


	.team-single-content {
		h2 {
			font-size: 48px;
			font-style: normal;
			font-weight: 700;
			line-height: 58px;
			margin-bottom: 20px;
			text-transform: capitalize;

			@media(max-width:991px) {
				font-size: 42px;
				line-height: 48px;
			}

			@media(max-width:575px) {
				font-size: 35px;
				margin-top: 0;
				line-height: 40px;

			}
		}

		p {
			margin-bottom: 0;
		}

		.skill-section {
			padding: 50px 40px 20px;
			background: $dark-gray;
			margin-top: 40px;

			@media(max-width:575px) {
				padding: 30px 20px;
			}

			.progress-single {
				margin-bottom: 30px;
				position: relative;

				h5 {
					font-size: 18px;
					font-style: normal;
					font-weight: 700;
					line-height: 28px;
					color: $white;

					@media(max-width:767px) {
						font-size: 18px;
					}
				}

				.progress {
					background: #2E2E2E;
					height: 6px;
					position: relative;

					.progress-bar {
						background-color: $theme-primary-color;
					}
				}
			}

			.progress-number {
				position: absolute;
				right: 0;
				top: 4px;
				font-size: 12px;
				color: $white;
				font-weight: 400;

				@media(max-width:767px) {
					font-size: 18px;
					top: -5px;
				}
			}
		}

		.service-wrap {
			margin-top: 60px;
			padding-bottom: 70px;

			@media(max-width:991px) {
				padding-bottom: 0;
			}


			.row {
				margin-top: 20px;
			}

			.service-item {
				border-bottom: 1px solid #D9D9D9;
				padding-bottom: 15px;
				margin-bottom: 30px;
				position: relative;

				&:before {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 0;
					height: 1px;
					content: "";
					background: $theme-primary-color;
					transition: all .4s;
				}

				.service-top {
					display: flex;
					align-items: center;
					margin-bottom: 20px;

					i {
						display: block;
						width: 86px;
						height: 86px;
						line-height: 86px;
						font-size: 45px;
						text-align: center;
						margin-right: 20px;
						transition: all .3s;
						border-radius: 5px;
					}
				}

				.service-text {
					h2 {
						color: $body-color;
						font-size: 48px;
						font-style: normal;
						font-weight: 700;
						line-height: 58px;
						margin-bottom: 0;
						text-transform: uppercase;

						@media(max-width:450px) {
							font-size: 38px;
						}
					}

					h3 {
						font-size: 24px;
						font-style: normal;
						font-weight: 700;
						line-height: 34px;
						text-transform: capitalize;

						@media(max-width:1199px) {
							font-size: 22px;
						}

						@media(max-width:450px) {
							font-size: 20px;
						}

						a {
							color: $dark-gray;

							&:hover {
								color: $theme-primary-color;
							}
						}
					}
				}

				&:hover {
					&:before {
						width: 100%;
					}

					.service-top {

						i {
							background: $theme-primary-color;
							color: $white;
						}
					}
				}
			}
		}

		.contact-area {
			background: none;
			padding: 0;

			.contact-form-area {
				padding: 120px 95px;
				box-shadow: 0px 4px 30px 0px rgba(149, 149, 149, 0.15);

				@media(max-width:1199px) {
					padding: 80px;
				}

				@media(max-width:991px) {
					padding: 50px;
				}

				@media(max-width:575px) {
					padding: 30px 15px;
				}
			}
		}
	}
}