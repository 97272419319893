/* =======================================
21. Services Page    
 =========================================*/

// service-single-page

.service-single-page {

	.service-single-text {
		margin-top: 30px;
		margin-bottom: 30px;

		h2 {
			font-size: 48px;
			font-style: normal;
			font-weight: 700;
			line-height: 58px;
			margin-bottom: 20px;
		}
	}

	.service-single-grap {
		.col {
			@media(min-width:991px) {
				padding: 0;
			}
		}
	}

	.service-single-grap-text {
		h3 {
			font-size: 30px;
			font-style: normal;
			font-weight: 700;
			line-height: 40px;
			margin-bottom: 20px;
		}

		ul {
			li {
				font-family: $heading-font;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 34px;
				margin-bottom: 20px;

				i {
					margin-right: 10px;
					color: $theme-primary-color;
				}
			}
		}
	}

	.service-wrap {
		margin-top: 30px;

		.service-item {
			border-bottom: 1px solid #D9D9D9;
			padding-bottom: 15px;
			margin-bottom: 30px;
			position: relative;

			&:before {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 0;
				height: 1px;
				content: "";
				background: $theme-primary-color;
				transition: all .4s;
			}

			.service-top {
				display: flex;
				align-items: center;
				margin-bottom: 20px;

				i {
					display: block;
					width: 86px;
					height: 86px;
					line-height: 86px;
					font-size: 45px;
					text-align: center;
					margin-right: 20px;
					transition: all .3s;
					border-radius: 5px;
				}
			}

			.service-text {
				h2 {
					color: $body-color;
					font-size: 48px;
					font-style: normal;
					font-weight: 700;
					line-height: 58px;
					margin-bottom: 0;

					@media(max-width:450px) {
						font-size: 38px;
					}
				}

				h3 {
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: 34px;

					@media(max-width:450px) {
						font-size: 20px;
					}

					a {
						color: $dark-gray;

						&:hover {
							color: $theme-primary-color;
						}
					}
				}
			}

			&:hover {
				&:before {
					width: 100%;
				}

				.service-top {

					i {
						background: $theme-primary-color;
						color: $white;
					}
				}
			}
		}
	}

	.faq-section-wrap {
		padding: 80px;
		background: $section-bg-color;
		margin-top: 70px;


		@media(max-width:767px){
			padding: 30px;
			margin-top: 40px;
		}

		.accordion-item {
			border: 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
			background: $section-bg-color;

			button {
				padding: 28px 0;
				color: $heading-color;
				text-align: left;
				font-family: $heading-font;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 34px;
				background: none;

				@media(max-width:1399px) {
					padding: 20px 0;
				}

				@media(max-width:1199px) {
					font-size: 20px;
					padding: 24px 0;
					line-height: 28px;
				}

				&::after {
					background: none;
					content: "\e906";
					font-family: "icomoon";
					font-size: 15px;
					font-weight: 700;
				}


				&.collapsed {
					color: #373b3e;
				}

				&:focus {
					box-shadow: none;
					outline: none;
					border-color: #e1e1e1;
				}


			}

			.accordion-body {
				padding: 20px 0;
			}

			.accordion-collapse {
				border: 0;
			}
		}
	}

	// service-sidebar
	.service-sidebar {

		@media(max-width:991px) {
			margin-top: 50px;
		}

		.service-widget {
			padding: 30px 20px;
			border: 1px solid rgba(0, 0, 0, 0.15);
			background: #F4F1FA;
			margin-bottom: 30px;

			ul {
				li {
					a {
						display: block;
						padding: 21px 20px;
						background: $white;
						border-bottom: 1px solid rgba(0, 0, 0, 0.15);
						font-family: $heading-font;
						font-size: 24px;
						font-style: normal;
						font-weight: 700;
						line-height: 34px;
						color: $dark-gray;
						position: relative;

						@media(max-width:1199px) {
							font-size: 18px;
							padding: 15px 20px;
						}

						&:after {
							position: absolute;
							right: 20px;
							top: 28px;
							content: "\e912";
							font-family: "icomoon";
							transform: rotate(-45deg);
							transition: all 0.3s;
							font-size: 10px;
							width: 20px;
							height: 20px;
							line-height: 20px;
							background: $text-color;
							border-radius: 50%;
							color: $white;
							text-align: center;

							@media(max-width:1199px) {
								top: 24px;
							}
						}

						&:hover {
							background: $theme-primary-color;
							color: $white;

							&:after {
								transform: rotate(0deg);
								background-color: $white;
								color: $theme-primary-color;
							}
						}
					}


					&:last-child {
						a {
							border: 0;
						}
					}
				}
			}
		}

		.info-widget {
			padding: 40px 20px 60px;
			background: $dark-gray;
			position: relative;
			z-index: 1;
			margin-bottom: 30px;

			.icon {
				position: absolute;
				right: 10px;
				bottom: 10px;
				z-index: -1;
			}

			h3 {
				text-align: center;
				color: $white;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 34px;
				margin-bottom: 30px;
			}

			.info-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 20px 30px;
				background: $theme-primary-color;
				margin-top: 20px;

				@media(max-width:1199px) {
					padding: 15px;
				}

				h4 {
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: 34px;
					margin-bottom: 0;
					display: flex;
					align-items: center;
					color: $white;

					@media(max-width:1199px) {
						font-size: 18px;
					}

					i {
						margin-right: 20px;
					}
				}

				i {
					color: $white;
					font-size: 25px;
				}

				&:last-child {
					background: $text-color;
				}
			}
		}

		.invest-widget {
			padding: 60px 50px;
			height: 450px;
			display: flex;
			justify-content: flex-end;
			flex-direction: column;
			position: relative;
			z-index: 1;

			@media(max-width:1199px) {
				padding: 30px 20px;
			}

			&::before {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				content: "";
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(31, 33, 45, 0.80) 64.57%);
				z-index: -1;
			}

			h3 {
				font-size: 30px;
				font-style: normal;
				font-weight: 700;
				line-height: 40px;
				color: $white;
				margin-bottom: 10px;
			}

			p {
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 29px;
				color: $white;
				margin-bottom: 30px;
			}
		}
	}
}

.service-text { padding-left: 30px;}