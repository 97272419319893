/* =============================
23. Project-page
 ===============================*/

/* case-section-s3 */
.project-page {
	.case-section-s3 {
		padding-bottom: 80px;

		@media(max-width:991px) {
			padding-bottom: 40px;
		}

		@media(max-width:767px) {
			padding-bottom: 30px;
		}

		.case-item {
			margin-bottom: 40px;
			box-shadow: 0 4px 20px 0 hsla(0,0%,75%,.25);
			border: 4px solid #fff;

			.case-text {
				position: relative;
				bottom: 0;
				padding: 0;
				

				span {
					color: $theme-primary-color;
				}

				h2 {
					margin-top: 3px;
					color: #fff;
					font-size: 22px;
					text-align: center;
					background-color: #ec2f30;
					padding: 10px 0;

					a {
						color: $dark-gray;
					}
				}

				p {
					margin-bottom: 0;
				}
			}
		}
	}
}

/* project-single-page */

.project-single-page {
	.project-single-text {
		margin-bottom: 60px;

		h2 {
			font-size: 48px;
			font-style: normal;
			font-weight: 700;
			line-height: 58px;
			margin-bottom: 20px;

			@media(max-width:991px) {
				font-size: 32px;
				line-height: 40px;
			}
			@media(max-width:575px) {
				font-size: 28px;
				line-height: 35px;
			}
		}
	}

	.problem {
		margin-bottom: 30px;

		h3 {
			font-size: 36px;
			font-style: normal;
			font-weight: 700;
			line-height: 46px;
			margin-bottom: 20px;

			@media(max-width:991px) {
				font-size: 32px;
				line-height: 40px;
			}
			@media(max-width:575px) {
				font-size: 28px;
				line-height: 35px;
			}
		}
	}

	.challenges {
		h4 {
			font-size: 30px;
			font-style: normal;
			font-weight: 700;
			line-height: 40px;
			margin-bottom: 30px;
			padding-bottom: 20px;
			border-bottom: 1px solid $border-color;

			@media(max-width:991px) {
				font-size: 32px;
				line-height: 40px;
			}
			@media(max-width:575px) {
				font-size: 25px;
				line-height: 35px;
			}

			i {
				color: $theme-primary-color;
			}
		}

		ul {

			li {
				padding-bottom: 15px;
			}
		}
	}

	// project-sidebar

	.project-sidebar {
		@media(max-width:991px) {
			margin-top: 40px;
		}

		.project-category {
			padding: 45px 48px;
			background: $section-bg-color;

			@media(max-width:1199px) {
				padding: 30px 20px;
			}

			ul {
				li {
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 34px;
					font-family: $heading-font;
					display: flex;

					@media(max-width:1199px) {
						font-size: 20px;
					}

					&+li {
						margin-top: 20px;
					}

					span {
						font-weight: 700;
						color: $dark-gray;
						flex-basis: 50%;
						display: flex;
						align-items: center;

						i {
							color: $theme-primary-color;
							margin-right: 20px;
						}
					}
				}
			}

			.cloud-btn {
				margin-top: 30px;

				button {
					width: 100%;
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: 34px;
					font-family: $heading-font;
					background: $theme-primary-color;
					padding: 18px 25px;
					border: 0;
					color: $white;
					display: flex;
					justify-content: space-between;
					align-items: center;

					@media(max-width:1199px) {
						font-size: 20px;
					}

					span {
						display: flex;
						align-items: center;

						i {
							margin-right: 20px;
						}
					}

					&:last-child {
						margin-top: 20px;
						background-color: $text-color;
					}
				}
			}
		}

		.project-side {
			margin-top: 80px;
			position: relative;

			img {
				width: 100%;
			}

			&:before {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				content: "";
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(31, 33, 45, 0.80) 100%);
			}
		}
	}

	.project-single-bottom {
		padding-top: 100px;

		h2 {
			font-size: 48px;
			font-style: normal;
			font-weight: 700;
			line-height: 58px;
			margin-bottom: 30px;

			@media(max-width:991px) {
				font-size: 32px;
				line-height: 40px;
			}
			@media(max-width:575px) {
				font-size: 28px;
				line-height: 35px;
			}
		}

		.challenges {
			ul {
				li {
					padding-left: 25px;
					position: relative;

					&:before {
						position: absolute;
						left: 0;
						top: 0;
						content: "\e914";
						font-family: 'icomoon';
						color: $theme-primary-color;
					}
				}
			}
		}

		.service-section {
			padding-bottom: 0;
			padding-top: 60px;
		}
	}
}