.heading-title,
.heading-title-s2,
.heading-title-s3 {
	margin-bottom: 28px;

	@include media-query(991px) {
		text-align: center;
		margin-bottom: 40px;
	}

	@include media-query(767px) {
		margin-bottom: 40px;
		text-align: center;
	}

	@include media-query(575px) {
		margin-bottom: 20px;
		text-align: center;
	}

	small {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 34px;
		display: inline-block;
		font-family: $heading-font;
		padding-bottom: 10px;
		margin-bottom: 15px;
		position: relative;
		color: $theme-primary-color;

		&:before {
			position: absolute;
			left: 0;
			bottom: 0;
			height: 1px;
			width: 63px;
			background: $theme-primary-color;
			content: "";

			@include media-query(991px) {
				left: 50%;
				transform: translateX(-50%);
			}
		}

		&:after {
			position: absolute;
			left: 0;
			bottom: -3px;
			height: 8px;
			width: 8px;
			background: $white;
			content: "";
			border: 1px solid $theme-primary-color;
			border-radius: 50%;

			@include media-query(991px) {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	h2 {
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: 50px;

		span {
			color: $theme-primary-color;
		}

		@include media-query(1399px) {
			font-size: 45px;
		}
		@include media-query(1199px) {
			font-size: 42px;
		}

		@include media-query(991px) {
			font-size: 42px;
			line-height: 48px;
		}

		@include media-query(575px) {
			font-size: 35px;
			margin-top: 0;
			line-height: 40px;
		}

	}

	h3 {
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;

		span {
			color: $theme-primary-color;
		}

		@include media-query(1399px) {
			font-size: 24px;
		}
		@include media-query(1199px) {
			font-size: 20px;
		}

		@include media-query(991px) {
			font-size: 20px;
			line-height: 28px;
		}

		@include media-query(575px) {
			font-size: 18px;
			margin-top: 0;
			line-height: 26px;
		}

	}

	p {
		font-size: 18px;
	}
}

.heading-title-s2 {
	text-align: center;

	small {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 34px;
		display: inline-block;
		font-family: $heading-font;
		padding-bottom: 10px;
		margin-bottom: 10px;
		position: relative;
		color: $theme-primary-color;
		text-transform: capitalize;

		&:before {
			left: 50%;
			transform: translateX(-50%);
		}

		&:after {
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

// heading-title-s3

.heading-title-s3 {
	small {
		&:after {
			background: transparent;
		}
		&:before {
			left: 8px;

			@media(max-width:991px){
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	h2 {
		color: $white;
	}
}