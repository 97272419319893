/* ============================
27. Contact Page
 ==============================*/

/* map-section */
.map-section {
	max-width: 1600px;
	margin: 0 auto;

	iframe {
		width: 100%;
		height: 400px;
	}
}

/* contact-page */

.contact-page {
	.office-info {

		@media(max-width:991px) {
			margin-top: 40px;
		}
		.heading-title {
			h2 {
				margin-bottom: 20px;
			}
		}
		.contact-info {
			margin-top: 30px;

			ul {
				li {
					font-size: 18px;
					font-style: normal;
					font-weight: 700;
					line-height: 28px;
					font-family: $heading-font;
					color: $dark-gray;

					@media(max-width:1199px) {
						font-size: 17px;
					}

					&+li {
						margin-top: 15px;
					}

					i {
						margin-right: 10px;
						color: $theme-primary-color;
					}
				}
			}
		}

		.info-social {
			margin-top: 30px;

			ul {
				display: flex;

				@media(max-width:991px) {
					justify-content: center;
				}

				li {
					&+li {
						margin-left: 20px;
					}

					a {
						display: block;
						width: 50px;
						height: 50px;
						line-height: 56px;
						background: $text-color;
						color: $white;
						border-radius: 50%;
						text-align: center;

						&:hover {
							background: $theme-primary-color;
						}
					}
				}
			}
		}

	}


	.contact-area-wrapper {
		position: relative;
		padding: 10px;
		background: $section-bg-color;

		@media(max-width:1199px) {
			padding: 10px;
		}

		@media(max-width:575px) {
			padding: 10px;
		}

		.contact-form-area {
			position: relative;
			z-index: 9;
			margin: 0 auto;
			background: $white;
			padding: 70px 30px;
			z-index: 1;


			@media(max-width:1199px) {
				padding: 30px;
			}

			h2 {
				font-size: 48px;
				font-style: normal;
				font-weight: 700;
				line-height: 58px;
				text-transform: capitalize;
				margin-bottom: 40px;

				@media(max-width:991px) {
					text-align: center;
				}

				@media(max-width:575px) {
					font-size: 30px;
					line-height: 48px;
					margin-bottom: 30px;
				}
			}

			@media (max-width:991px) {
				padding: 20px;
			}

			.errorMessage {
				color: red;
				margin-bottom: 5px;
			}

			label {
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: 28px;
				font-family: $heading-font;
				margin-bottom: 15px;
			}

			.form-control {
				width: 100%;
				height: 60px;
				margin-bottom: 20px;
				border: 0;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 29px;
				color: #A5A5A5;
				border: 1px solid rgba(31, 33, 45, 0.15);

				&:focus {
					outline: none;
					box-shadow: none;
				}

				&::-webkit-input-placeholder {
					color: $text-color;
				}

				&:-ms-input-placeholder {
					color: $text-color;
				}

				&::placeholder {
					color: $text-color;
				}
			}

			textarea {
				&.form-control {
					height: 130px;
				}
			}

			select.form-control {
				color: $text-color;
				-webkit-appearance: none;
				-ms-appearance: none;
				-o-appearance: none;
				appearance: none;
				-moz-appearance: none;
				background: $white url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
				position: relative;

				option {
					border: 0;
				}
			}

			.submit-area {
				margin-top: 20px;

				@media(max-width:991px) {
					text-align: center;
				}
			}
		}
	}
}